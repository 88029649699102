/** @jsx jsx */

import React from 'react'
import { jsx } from 'theme-ui'

const hidden = [`none`, `none`, `block`]

const icons = {
  back: {
    shape: (
      <path
        fill="#282828"
        fillOpacity={0.5}
        d="M607.185455 301.847273c6.376727 6.749091 9.541818 15.36 9.541818 23.970909 0 9.262545-3.653818 18.501818-10.938182 25.367273l-160.768 152.040727 162.117818 170.938182a34.909091 34.909091 0 0 1-50.641454 48.034909l-186.181819-196.282182a33.326545 33.326545 0 0 1-9.565091-24.971636c0.256-9.262545 4.165818-18.059636 10.91491-24.413091l186.181818-176.081455a34.885818 34.885818 0 0 1 49.338182 1.396364M23.272727 512c0 269.917091 218.810182 488.727273 488.727273 488.727273s488.727273-218.810182 488.727273-488.727273c0-269.893818-218.810182-488.727273-488.727273-488.727273S23.272727 242.106182 23.272727 512"
      />
    ),
    viewBox: `0 0 1024 1024`
  },
  triangle: {
    shape: (
      <polygon
        strokeWidth="1px"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="14.921,2.27 28.667,25.5 1.175,25.5 "
      />
    ),
    viewBox: `0 0 30 30`
  },
  circle: {
    shape: (
      <path d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,6.23A8.77,8.77,0,1,0,23.77,15,8.77,8.77,0,0,0,15,6.23Z" />
    ),
    viewBox: `0 0 30 30`
  },
  arrowUp: {
    shape: (
      <React.Fragment>
        <path d="M28.74,20.81H1.26a1.26,1.26,0,0,1-1-2L14.16.5a1.25,1.25,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,18.8a1.25,1.25,0,0,1-1,2ZM3.81,18.29H26.22L15.16,3.37Z" />
        {` `}
        <path d="M28.74,42H1.26a1.28,1.28,0,0,1-1.13-.71A1.26,1.26,0,0,1,.26,40l13.9-18.29a1.28,1.28,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,40a1.26,1.26,0,0,1,.12,1.32A1.28,1.28,0,0,1,28.74,42ZM3.81,39.47H26.22L15.16,24.55Z" />
      </React.Fragment>
    ),
    viewBox: `0 0 30 42`
  },
  upDown: {
    shape: (
      <React.Fragment>
        <path d="M28.74,44.58a1.28,1.28,0,0,1-1-.51L15.16,27.13l-12.89,17a1.26,1.26,0,1,1-2-1.53l13.9-18.29a1.34,1.34,0,0,1,1-.5,1.24,1.24,0,0,1,1,.51L29.75,42.56a1.27,1.27,0,0,1-1,2Z" />
        <path d="M14.83,20.82h0a1.28,1.28,0,0,1-1-.52L.25,2a1.27,1.27,0,0,1,2-1.51L14.84,17.45,27.73.5a1.26,1.26,0,0,1,2,1.53L15.84,20.32A1.28,1.28,0,0,1,14.83,20.82Z" />
      </React.Fragment>
    ),
    viewBox: `0 0 30 44.58`
  },
  box: {
    shape: (
      <path d="M28,2V28H2V2H28m.13-2H1.88A1.88,1.88,0,0,0,0,1.88V28.13A1.88,1.88,0,0,0,1.88,30H28.13A1.87,1.87,0,0,0,30,28.13V1.88A1.88,1.88,0,0,0,28.13,0Z" />
    ),
    viewBox: `0 0 30 30`
  },
  hexa: {
    shape: (
      <polygon
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="27.5,21.904 15,28.809  2.5,21.904 2.5,8.095 15,1.19 27.5,8.095 "
      />
    ),
    viewBox: `0 0 30 30`
  },
  cross: {
    shape: (
      <path
        strokeWidth="3px"
        d="M60.5,50l34.8-34.8c2.9-2.9,2.9-7.6,0-10.5c-2.9-2.9-7.6-2.9-10.5,0L50,39.5L15.2,4.7c-2.9-2.9-7.6-2.9-10.5,0    c-2.9,2.9-2.9,7.6,0,10.5L39.5,50L4.7,84.8c-2.9,2.9-2.9,7.6,0,10.5c1.4,1.4,3.3,2.2,5.2,2.2s3.8-0.7,5.2-2.2L50,60.5l34.8,34.8    c1.4,1.4,3.3,2.2,5.2,2.2c1.9,0,3.8-0.7,5.2-2.2c2.9-2.9,2.9-7.6,0-10.5L60.5,50z"
      />
    ),
    viewBox: `0 0 100 100`
  }
}

export const partials = [
  {
    id: 'blog',
    path: `M155.681,213.902c-28.708,0-57.415,0.001-86.123,0c-5.327,0-5.502-0.148-5.505-5.49
    c-0.013-30.75-0.016-61.499-0.01-92.249c0.001-5.316,0.157-5.48,5.529-5.482c57.417-0.019,114.834-0.028,172.252-0.028
    c5.344,0,5.492,0.15,5.493,5.512c0.009,30.75,0.005,61.499-0.009,92.247c-0.003,5.351-0.159,5.507-5.502,5.51
    c-28.709,0.013-57.417,0.005-86.125,0.005C155.681,213.92,155.681,213.912,155.681,213.902z`,
    position: { x: 75, y: 135 },
    transform: 'matrix(1 0 0 1 75 135)'
  },
  {
    id: 'about',
    path: `M378.533,5.24c0.673-0.855,2.3-1.417,3.42-1.317c0.866,0.077,1.855,1.269,2.354,2.2
    c0.406,0.757,0.208,1.861,0.208,2.812c-0.001,78.257-0.03,156.516,0.044,234.772c0.006,3.821-1.077,5.278-5.072,5.266
    c-41.373-0.119-82.746-0.125-124.12-0.046c-3.654,0.006-5.051-1.101-5.045-4.895c0.088-48.724,0.251-97.448-0.116-146.169
    c-0.072-9.501,0.735-17.564,9.597-23.125L378.533,5.24z`,
    position: { x: 265, y: 240 },
    transform: 'matrix(1 0 0 1 265 240)'
  },
  {
    id: 'music',
    path: `M256.307,330.646c-5.912,0-5.966-0.07-5.974-5.869c-0.007-6.123-0.038-12.245,0.006-18.367l-0.023-17.579
  c0.013-10.339,0.161-20.681,0.017-31.019c-0.052-3.715,1.272-4.987,4.991-4.982c56.325,0.068,112.65,0.033,168.978,0.021
  c2.585,0,5.171,0.058,7.754-0.037c2.801-0.104,3.925,1.199,3.871,3.933l0.047,69.373c0.041,3.362-1.241,4.612-4.566,4.522
  L256.307,330.646z`,
    position: { x: 265, y: 320 },
    transform: 'matrix(1 0 0 1 265 320)'
  },
  {
    id: 'work',
    path: `M247.323,489.883c0,1.463-0.141,2.927-0.291,5.826c-4.047-3.169-7.095-5.556-10.14-7.943
    c-19.886-15.598-39.799-31.164-59.65-46.806c-13.658-10.763-27.21-21.662-40.863-32.431c-2.539-2.002-3.736-4.244-3.731-7.61
    c0.099-59.317,0.082-118.635,0.107-177.951c0.003-5.397,0.143-5.512,5.474-5.514c34.546-0.013,69.093-0.017,103.64-0.015
    c5.231,0.001,5.517,0.513,5.383,5.546L247.323,489.883z`,
    position: { x: 140, y: 240 },
    transform: 'matrix(1 0 0 1 140 240)'
  }
]

export const links = [
  {
    id: 'instagram',
    logoPath: `M329.013,364.396c1.278,0,1.431,0.005,1.937,0.028c1.3,0.06,1.906,0.677,1.967,1.966
    c0.022,0.506,0.026,0.658,0.026,1.938c0,1.28-0.004,1.431-0.026,1.937c-0.062,1.288-0.666,1.906-1.967,1.966
    c-0.506,0.021-0.656,0.027-1.937,0.027c-1.279,0-1.431-0.006-1.938-0.027c-1.302-0.061-1.906-0.68-1.966-1.966
    c-0.024-0.506-0.028-0.657-0.028-1.937s0.004-1.432,0.028-1.938c0.06-1.289,0.666-1.906,1.966-1.966
    C327.58,364.401,327.733,364.396,329.013,364.396z M329.013,363.533c-1.301,0-1.465,0.005-1.978,0.029
    c-1.74,0.079-2.706,1.045-2.787,2.787c-0.024,0.513-0.029,0.676-0.029,1.978s0.005,1.464,0.029,1.976
    c0.08,1.742,1.045,2.708,2.787,2.788c0.514,0.024,0.677,0.029,1.978,0.029c1.302,0,1.465-0.005,1.977-0.029
    c1.739-0.08,2.709-1.045,2.788-2.788c0.023-0.513,0.028-0.674,0.028-1.976s-0.005-1.465-0.028-1.978
    c-0.079-1.74-1.045-2.707-2.788-2.787C330.477,363.538,330.314,363.533,329.013,363.533z M329.013,365.866
    c-1.36,0-2.462,1.103-2.462,2.463c0,1.359,1.102,2.461,2.462,2.461c1.359,0,2.462-1.101,2.462-2.461
    S330.372,365.866,329.013,365.866z M329.013,369.926c-0.882,0-1.599-0.716-1.599-1.598c0-0.881,0.716-1.6,1.599-1.6
    c0.882,0,1.599,0.716,1.599,1.6C330.61,369.21,329.895,369.926,329.013,369.926z M331.572,365.194
    c-0.318,0-0.576,0.257-0.576,0.574c0,0.318,0.258,0.575,0.576,0.575c0.316,0,0.573-0.257,0.573-0.575
    C332.146,365.451,331.889,365.194,331.572,365.194z`,
    link: 'http://instagram.com/sumchattering',
    backgroundFill: '#c12354',
    backgroundPath: `M338.745,355.443c-0.002,5.574-0.104,11.152,0.037,16.726c0.081,3.194-1.258,4.312-4.368,4.284
    c-11.422-0.1-22.844-0.086-34.266-0.008c-2.91,0.019-4.193-1.049-4.174-4.055c0.08-11.559,0.079-23.117,0-34.674
    c-0.021-2.992,1.23-4.093,4.154-4.072c11.421,0.078,22.846,0.113,34.266-0.021c3.486-0.04,4.475,1.483,4.393,4.687
    C338.642,344.02,338.747,349.732,338.745,355.443z`
  },
  {
    id: 'facebook',
    logoPath: `M378.892,366.218h-1.021c-0.326,0-0.571,0.135-0.571,0.473v0.589h1.592l-0.127,1.592
    h-1.466v4.248h-1.592v-4.248h-1.062v-1.592h1.062v-1.02c0-1.074,0.565-1.634,1.838-1.634h1.347V366.218z`,
    link: 'http://facebook.com/sumchattering',
    backgroundFill: '#3b5999',
    backgroundPath: `M362.829,376.408c-5.573,0.001-11.149-0.092-16.72,0.037c-3.081,0.07-4.432-1.03-4.4-4.252
    c0.105-11.416,0.104-22.836,0-34.254c-0.028-3.176,1.233-4.324,4.355-4.297c11.417,0.101,22.835,0.086,34.253,0.01
    c2.896-0.021,4.207,1.021,4.187,4.039c-0.079,11.554-0.078,23.107,0.002,34.66c0.02,2.979-1.209,4.14-4.144,4.084
    C374.52,376.331,368.673,376.404,362.829,376.408z`
  },
  {
    id: 'twitter',
    logoPath: `M323.946,418.582c5.428,0,8.396-4.499,8.396-8.398c0-0.127,0-0.255-0.008-0.381
    c0.577-0.418,1.076-0.937,1.472-1.526c-0.539,0.237-1.111,0.394-1.694,0.464c0.615-0.37,1.076-0.95,1.297-1.635
    c-0.58,0.346-1.213,0.587-1.874,0.717c-1.117-1.188-2.986-1.245-4.177-0.128c-0.766,0.722-1.091,1.797-0.854,2.822
    c-2.374-0.12-4.583-1.241-6.082-3.086c-0.784,1.349-0.384,3.074,0.914,3.94c-0.471-0.014-0.931-0.14-1.341-0.369
    c0,0.012,0,0.023,0,0.036c0,1.405,0.991,2.614,2.368,2.894c-0.434,0.118-0.89,0.136-1.333,0.053
    c0.388,1.201,1.495,2.024,2.758,2.05c-1.045,0.82-2.336,1.266-3.664,1.265c-0.236,0-0.471-0.016-0.703-0.042
    c1.35,0.866,2.919,1.325,4.524,1.322`,
    link: 'http://twitter.com/sumchattering',
    backgroundFill: '#55aded',
    backgroundPath: `M338.667,400.948c0,5.579-0.088,11.159,0.035,16.733c0.067,3.071-1.02,4.46-4.248,4.433
    c-11.429-0.103-22.859-0.086-34.289-0.011c-2.967,0.021-4.133-1.193-4.112-4.139c0.074-11.43,0.092-22.859-0.011-34.289
    c-0.028-3.253,1.411-4.248,4.456-4.224c11.292,0.09,22.587,0.097,33.879-0.001c3.161-0.028,4.398,1.213,4.327,4.354
    C338.576,389.518,338.668,395.232,338.667,400.948z`
  }
]

type IconType =
  | 'back'
  | 'triangle'
  | 'circle'
  | 'arrowUp'
  | 'upDown'
  | 'box'
  | 'hexa'
  | 'cross'

type SVGProps = {
  stroke?: boolean
  color?: string | number | any
  width: number
  icon: IconType
  left: string
  top: string
  hiddenMobile?: boolean
}

const defaultProps = {
  stroke: false,
  hiddenMobile: false
}

export const Svg = ({
  stroke,
  color,
  width,
  icon,
  left,
  top,
  hiddenMobile
}: SVGProps) => (
  <svg
    sx={{
      stroke: stroke ? `currentColor` : `none`,
      fill: stroke ? `none` : `currentColor`,
      display: hiddenMobile ? hidden : `block`,
      color,
      width,
      left,
      top
    }}
    viewBox={icons[icon].viewBox}
  >
    {icons[icon].shape}
  </svg>
)

Svg.defaultProps = defaultProps
