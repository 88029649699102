/** @jsx jsx */

import { graphql, Link } from "gatsby";
import { css, jsx } from "@emotion/core";
import { useTrail, animated } from "react-spring";
import { Layout } from "../blog/components/layout";

const config = { mass: 5, tension: 2000, friction: 200 };

const Blog = ({ data, location }) => {
  const posts = data.allMdx.edges;
  const trail = useTrail(posts.length, {
    config,
    opacity: 1,
    x: 0,
    y: 0,
    from: { opacity: 0, x: 20, y: -20 }
  });

  return (
    <Layout
      location={location}
      headerTitle={"blog"}
      pageTitle="Blog / Sumeru Chatterjee"
    >
      {trail.map(({ x, y, ...rest }, i) => (
        <animated.div
          key={i}
          style={{
            ...rest,
            transform: x.interpolate(x => `translate3d(0, ${x}px, 0)`)
          }}
        >
          <Post node={posts[i].node} />
        </animated.div>
      ))}
    </Layout>
  );
};

function Post({ node }) {
  let title = node.frontmatter.title || node.fields.slug;

  return (
    <div>
      <h3
        css={css`
          margin-bottom: 30;
          margin-top: 60px;
        `}
      >
        <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
          {title}
        </Link>
      </h3>
      <small
        css={css`
          color: rgba(26, 27, 27, 0.7);
        `}
      >
        {node.frontmatter.date}
      </small>
      <p>{node.frontmatter.description}</p>
      {/* <p dangerouslySetInnerHTML={{ __html: node.excerpt }} /> */}
    </div>
  );
}

export default Blog;

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { draft: { ne: true } }
        fileAbsolutePath: { regex: "/blog/" }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
