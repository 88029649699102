/** @jsx jsx */

import React from 'react'
import { css, jsx, Global } from '@emotion/core'
import { Header } from './header'
import { Seo } from './seo'

type LayoutProps = {
  children?: React.ReactNode
  location: Location
  headerTitle: string
  pageTitle?: string
}

const BREAKPOINTS = [576, 768, 960, 1280]
export const mq = BREAKPOINTS.map(bp => `@media (max-width: ${bp}px)`)

export const Layout = ({
  children,
  location,
  headerTitle,
  pageTitle
}: LayoutProps) => {

  return (
    <main
      css={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: '960px',
        padding: '48px',
        [mq[0]]: {
          padding: '24px'
        }
      }}
    >
      <Seo title={pageTitle} pathname={location.pathname} />
      <Global
        styles={css({
          '*': {
            boxSizing: `inherit`,
            '&:before': {
              boxSizing: `inherit`
            },
            '&:after': {
              boxSizing: `inherit`
            }
          },
          html: {
            fontSize: `17px`
          },
          body: {
            background: '#ebebeb',
            boxSizing: `border-box`,
            color: '#282828',
            margin: 0,
            padding: 0,
            textRendering: `optimizeLegibility`,
            WebkitFontSmoothing: `antialiased`,
            MozOsxFontSmoothing: `grayscale`
          },
          h4: {
            fontSize: '1.25em',
            letterSpacing: 'initial',
            margin: '0 0 1.45rem',
            textTransform: 'initial'
          },
          h1: {
            margin: '60px 0 1.45rem',
          },
          p: {
            fontWeight: 400,
            lineHeight: '1.5em'
          },
          a: {
            fontWeight: 700
          },
          '.gatsby-resp-image-wrapper': {
            marginBottom: '1em'
          },
          'svg:not(root)': {
            overflow: 'visible !important'
          }
        })}
      />

      <Header title={headerTitle} location={location} />

      {children}
    </main>
  )
}
