/** @jsx jsx */

import React from 'react'
import { Link } from 'gatsby'
import { css, jsx } from '@emotion/core'
import { Svg } from './svg'
import { mq } from './layout'

type HeaderProps = {
  title: string
  location: Location
}

export const Header = ({ title, location }: HeaderProps) => {
  const { pathname } = location

  // Super naive check to see if we're inside of a blog post
  const isHome = pathname === '/'
  const isBlog = pathname.includes('/blog/') && pathname.split('/').length > 3
  const backDestination = isBlog ? '/blog' : '/'

  return (
    <div
      css={css`
        align-items: center;
        border-bottom: ${isHome ? 'none' : '1px solid rgba(32, 32, 32, 0.5)'};
        display: flex;
        justify-content: ${isHome ? 'center' : 'space-between'};
        padding: 1em 0;
      `}
    >
      {!isHome && (
        <Link
          to={backDestination}
          css={css`
            box-shadow: none;
            transition: opacity 0.2s ease-out;
            &:hover {
              opacity: 0.3;
            }
          `}
        >
          <Svg icon="back" width={32} />
        </Link>
      )}

      <div>
        <h1
          css={css`
            font-size: 1.5em;
            font-weight: 700;
            margin: 0;
            opacity: 0.66;
            text-align: ${isHome ? 'center' : 'right'};
            text-transform: lowercase;
            transition: all 0.5s ease-out;
            white-space: nowrap;
            ${mq[0]} {
              font-size: 1.15em;
            }
          `}
        >
          <Link
            to={`/`}
            style={{
              color: `inherit`,
              boxShadow: `none`,
              textDecoration: `none`
            }}
          >
            {title}
          </Link>
        </h1>
        {!isHome && (
          <p
            css={css`
              margin: 0;
            `}
          >
            <Link
              to={`/`}
              style={{
                boxShadow: `none`,
                textDecoration: `none`
              }}
            >
              sumeru chatterjee
            </Link>
          </p>
        )}
      </div>
    </div>
  )
}
