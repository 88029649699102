import { graphql, useStaticQuery } from 'gatsby'

type Props = {
  site: {
    siteMetadata: {
      [key: string]: string
    }
  }
}

const useSiteMetadata = () => {
  const data = useStaticQuery<Props>(graphql`
    query {
      site {
        siteMetadata {
          title
          author
          description
          url
          image
          social {
            twitter
            instagram
            github
          }
        }
      }
    }
  `)

  return data.site.siteMetadata
}

export default useSiteMetadata
